import { getParams } from "../../components/utils/SetupApp";
import { setIotCommands, setIotData } from "../redux/BuildingSlice";

export function executeIotCalls(dispatch, iotCalls) {
  for (const iot in iotCalls) {
    executeSitemapCall(iotCalls[iot])
      .then((data) => {
        dispatch(setIotData({ data, iot }));
      })
      .catch((e) => console.log(e));
  }
}

export function executeIotSitemapCalls(dispatch, iotSitemapCalls) {
  for (const iot in iotSitemapCalls) {
    const commandObject = iotSitemapCalls[iot];
    executeSitemapCall(iotSitemapCalls[iot])
      .then((sitemapData) => {
        const commands = {};
        const { homepage } = JSON.parse(sitemapData);
        let ohItems = [];
        let { widgets } = homepage;
        extractItems(widgets, ohItems);
        ohItems.forEach(({ mappings, item }) => {
          const data = mappings.length && item.state !== "NULL" ? mappings.filter((e) => e.command === item.state)[0].label : item.state;
          dispatch(setIotData({ data, iot: item.name }));
          commands[item.name] = { ...commandObject, url: `${commandObject.url.split("/sitemaps")[0]}/items/${item.name}/state` };
        });
        dispatch(setIotCommands(commands));
      })
      .catch((e) => console.log(e));
  }
}

function extractItems(widgets, ohItems) {
  widgets.forEach((element) => {
    if (element.item) {
      ohItems.push(element);
    } else if (element.linkedPage) {
      extractItems(element.linkedPage.widgets, ohItems);
    } else if (element.widgets) {
      extractItems(element.widgets, ohItems);
    }
  });
}

export function executeSitemapCall(sitemap) {
  return new Promise(function (resolve, reject) {
    const httpRequest = new XMLHttpRequest();
    // eslint-disable-next-line no-unused-vars
    const params = getParams();

    let url;
    if (sitemap.url.slice(0, 1) === "`") {
      // eslint-disable-next-line no-eval
      url = eval(sitemap.url);
    } else {
      url = sitemap.url;
    }

    httpRequest.open("GET", url);
    for (const header in sitemap.headers) {
      httpRequest.setRequestHeader(header, sitemap.headers[header]);
    }

    httpRequest.onerror = function (err) {
      reject(err);
    };
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
      if (this.status === 200) {
        if (this.readyState === 4) {
          resolve(httpRequest.responseText);
        }
      } else {
        reject(this.status);
      }
    };
  });
}

export function executePUTRequest(sitemap, data) {
  return new Promise(function (resolve, reject) {
    const httpRequest = new XMLHttpRequest();
    // eslint-disable-next-line no-unused-vars
    const params = getParams();

    let url;
    if (sitemap.url.slice(0, 1) === "`") {
      // eslint-disable-next-line no-eval
      url = eval(sitemap.url);
    } else {
      url = sitemap.url.slice(0, -6);
    }

    httpRequest.open("POST", url);
    for (const header in sitemap.headers) {
      httpRequest.setRequestHeader(header, sitemap.headers[header]);
    }

    httpRequest.onerror = function (err) {
      reject(err);
    };
    httpRequest.send(data);
    httpRequest.onreadystatechange = function () {
      if (this.status === 405 || this.status === 200) {
        if (this.readyState === 4) {
          resolve("Accepted");
        }
      } else {
        reject(this.status);
      }
    };
  });
}

export function hasPersistenceGotData(data) {
  if (!data) return false;
  if (!data.data) return false;
  if (Number(data.datapoints) === 0) return false;
  return true;
}
