import Box from "@mui/material/Box";
import packageJson from "../../../../package.json";
import Typography from "@mui/material/Typography";
import { footerHeight } from "../../../services/mui/settings";

export default function Footer() {
  return (
    <Box
      height={footerHeight}
      position={"relative"}
      bottom={0}
      right={2}
      sx={{
        justifyContent: "flex-end",
        display: "flex",
        mb: 0.5,
        mt: 0.5,
        mr: 1,
      }}
    >
      <Typography variant="caption" color="initial">
        Copyright ©2023-2033 - EC3 AA Ltd - Platform V{packageJson.version}
      </Typography>
    </Box>
  );
}
